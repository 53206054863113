import React, { } from 'react';
import { Link } from "react-router-dom";

import logoWideHeader from './assets/img/logo-wide-header.png';
import tagLine from './assets/img/tagline.png';

const Header = ( props:any ) => {
    const { title, navOn } = props;
    return (
        <header>
            <div className="header-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-5 col-sm-6">
                            <img src={logoWideHeader} className="img-responsive logo" />
                        </div>
                        <div className="col-lg-8 col-md-7 col-sm-6 text-right">
                            <div id="contact-info">
                                <span className="phone hidden-xs">724-933-5656</span>
                                <a href="tel:1724-933-5656" className="visible-xs">
                                        <div className="fa-stack fa-lg">
                                            <i className="fa fa-circle fa-stack-2x"></i>
                                            <i className="fa fa-phone fa-stack-1x"></i>
                                        </div>
                                </a>
                                <a href="https://www.facebook.com/InnovativeAutoworks" target="_blank">
                                    <div className="fa-stack fa-lg">
                                        <i className="fa fa-circle fa-stack-2x"></i>
                                        <i className="fa fa-facebook fa-stack-1x"></i>
                                    </div>
                                </a>
                                <a href="http://twitter.com/iaw_pres" target="_blank">
                                    <div className="fa-stack fa-lg">
                                        <i className="fa fa-circle fa-stack-2x"></i>
                                        <i className="fa fa-twitter fa-stack-1x"></i>
                                    </div>
                                </a>
                                <a href="https://www.instagram.com/innovativeauto" target="_blank">
                                    <div className="fa-stack fa-lg">
                                        <i className="fa fa-circle fa-stack-2x"></i>
                                        <i className="fa fa-instagram fa-stack-1x"></i>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="header-bottom">
                <div className="header-bottom-2">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 text-center">
                                <nav className="mobileNavOpen">
                                    <ul>
                                        <li className={navOn==='home'?'on':''}><Link to="/">Home</Link></li>
                                        <li className={navOn==='services'?'on':''}><Link to="/services">Services</Link></li>
                                        <li className={navOn==='gallery'?'on':''}><Link to="/gallery">Gallery</Link></li>
                                        <li className={navOn==='aboutus'?'on':''}><Link to="/aboutus">AboutUs</Link></li>
                                        <li className={navOn==='contactus'?'on':''}><Link to="/contactus">ContactUs</Link></li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-sm-12 text-center">
                        <div>
                            { title === 'Home' ?
                                <img src={tagLine} className="tagline" alt="Pittsburgh's Intersection of Automotive Passion & Technology" />
                                :
                                <h1 className="subpage-title">{ title }</h1>
                            }
                        </div>
                    </div>
                </div>
            </div >
        </header>
    );
}

export default Header;


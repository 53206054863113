import React, { } from 'react';
import { Link } from "react-router-dom";

import Header from '../Header';
import about1 from '../assets/img/about1.png';
import abouttj from '../assets/img/about-tj.png';
import about3 from '../assets/img/about3.png';

function AboutUs() {
    return (
        <div className="AboutUs">
            <div className="angle-feature subpage">
                <Header title="About Us" />
            </div>
            <div className="container padded">
                <div className="row">
                    <div className="col-sm-12">
                        <h4>Innovative Autoworks is a full-service shop offering the highest quality auto body repair and vehicle customizations.<br />&nbsp;</h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-7 about-mosaic">
                        <img src={about3} />
                        <img src={about1} />
                        <img src={abouttj} />
                        <br /><br />
                    </div>
                    <div className="col-sm-5">
                        <p>Innovative Autoworks has been building custom cars and trucks for 15 years. And we've been part of the culture much longer than that — as customers and enthusiasts. So we've stood in your shoes, and we know what your expectations are.</p>
                        <p>Our mission at IAW is to meet and exceed those expectations.</p>
                        <p>Our customers come to us because they expect to stand out. They aren't satisfied with run-of-the mill styling or factory performance. When you choose IAW for a custom project, whether it's a complete restoration, a restomod, a sound system makeover, or something as functional as lifting your truck for extreme off-road operation, you can expect that we will make it our first priority to understand your unique vision, and capture it completely. With that understanding, we'll show you how our experience and our design sense will make your vision a reality.</p>
                        <p>We've built vehicles of all different kinds: the kind that win awards and go to industry shows like SEMA as well as the kind that go, with style, to work every day. And what we've learned is that there is always a way, with any vehicle and any budget, to make a statement that is original and unique.</p>
                        <p>One of the things that makes <em>us</em> unique is our ability to think beyond the current trends in custom car design. Beyond the expected. Ahead of the curve. We enjoy all of our projects, but especially those that challenge us to do what has not been done before. When customers tell us, for example, that we've put colors and options together in a way they'd never imagined could be done, and it works … that's when we know we got it right.</p>
                        <p>We're set up to provide that experience for you, efficiently and professionally. We have a fully-equipped shop with state of the art systems and tools. And we have an extremely efficient supply chain that makes sure that, whatever the project, we can meet your time and budget deadlines. In other words, we're not afraid to take it on — whatever it is.</p>
                        <p>And because we've stood where you stand, we know that you expect dedicated, personal service. Which is why we're structured to keep you informed about your project at all times, respond to your needs, and exceed your expectations.</p>
                        <p>Because, in your shoes, we'd expect no less.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutUs;

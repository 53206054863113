import React from 'react';
import { Route, Routes, BrowserRouter, Link } from 'react-router-dom';
import './App.css';
import './iaw.scss';
import Header from './Header';
import Footer from './Footer';
import Home from './screens/Home';
import Services from './screens/Services';
import Gallery from './screens/Gallery';
import AboutUs from './screens/AboutUs';
import ContactUs from './screens/ContactUs';
import AutoBody from './screens/AutoBody';
import Restyling from './screens/Restyling';
import Suspension from './screens/Suspension';
import Powdercoating from './screens/Powdercoating';
import RequestAppointment from './screens/RequestAppointment';

function AppRouter( props:any ) {
    const { galleryPhotos } = props;

    return (
        <div>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route path="/header" element={<Header />} />
                <Route path="/footer" element={<Footer />} />
                <Route path="/services" element={<Services />} />
                <Route path="/gallery" element={<Gallery />} />
                <Route path="/aboutus" element={<AboutUs />} />
                <Route path="/contactus" element={<ContactUs />} />

                <Route path="/autobody" element={<AutoBody />} />
                <Route path="/restyling" element={<Restyling />} />
                <Route path="/suspension" element={<Suspension />} />
                <Route path="/powdercoating" element={<Powdercoating />} />

                <Route path="/requestappointment" element={<RequestAppointment />} />
            </Routes>
        </div>

    );
}

export default AppRouter;
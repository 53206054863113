export const blankContactInfo:any = {
    "Email": null,
    "MapUrl": null,
    "Phone": null,
    "Fax": null,
    "Address": {
        "Label": null,
        "IsDefault": false,
        "LineOne": null,
        "LineTwo": null,
        "City": null,
        "State": {
            "Abbreviation": null,
            "Name": null
        },
        "Zipcode": null
    },
    "SocialMediaURLs": {
        "Facebook": null,
        "Twitter": null,
        "Instagram": null,
        "LinkedIn": null,
        "YouTube": null
    },
    "HoursOfOperation": {
        "Sunday": null,
        "Monday": null,
        "Tuesday": null,
        "Wednesday": null,
        "Thursday": null,
        "Friday": null,
        "Saturday": null,
        "Abbreviated": null
    }
};

export const galleries:any = {
    "Galleries": [
        {
            "Photos": [
                {
                    "Caption": null,
                    "Rank": null,
                    "Location": null,
                    "Date": null,
                    "Cover": null,
                    "Photo": {
                        "Name": "Red Motorcycle",
                        "Uri": "/e/locker/id/5a54fc9209e15932b0c5a043",
                        "Description": null,
                        "IsDefault": false,
                        "Type": null,
                        "Id": "5a54fc9209e15932b0c5a043"
                    }
                },
                {
                    "Caption": null,
                    "Rank": null,
                    "Location": null,
                    "Date": null,
                    "Cover": null,
                    "Photo": {
                        "Name": null,
                        "Uri": "/e/locker/id/5a54fc9809e1593b18936cf7",
                        "Description": null,
                        "IsDefault": false,
                        "Type": null,
                        "Id": "5a54fc9809e1593b18936cf7"
                    }
                },
                {
                    "Caption": null,
                    "Rank": null,
                    "Location": null,
                    "Date": null,
                    "Cover": null,
                    "Photo": {
                        "Name": null,
                        "Uri": "/e/locker/id/5a54fc9d09e15932b0c5a045",
                        "Description": null,
                        "IsDefault": false,
                        "Type": null,
                        "Id": "5a54fc9d09e15932b0c5a045"
                    }
                },
                {
                    "Caption": null,
                    "Rank": null,
                    "Location": null,
                    "Date": null,
                    "Cover": null,
                    "Photo": {
                        "Name": null,
                        "Uri": "/e/locker/id/5a54fca509e15932b0c5a047",
                        "Description": null,
                        "IsDefault": false,
                        "Type": null,
                        "Id": "5a54fca509e15932b0c5a047"
                    }
                },
                {
                    "Caption": null,
                    "Rank": null,
                    "Location": null,
                    "Date": null,
                    "Cover": null,
                    "Photo": {
                        "Name": null,
                        "Uri": "/e/locker/id/5a54fcab09e1593d009a2f46",
                        "Description": null,
                        "IsDefault": false,
                        "Type": null,
                        "Id": "5a54fcab09e1593d009a2f46"
                    }
                },
                {
                    "Caption": null,
                    "Rank": null,
                    "Location": null,
                    "Date": null,
                    "Cover": null,
                    "Photo": {
                        "Name": null,
                        "Uri": "/e/locker/id/5a54fcb209e1593d009a2f48",
                        "Description": null,
                        "IsDefault": false,
                        "Type": null,
                        "Id": "5a54fcb209e1593d009a2f48"
                    }
                }
            ],
            "Slug": "redmotorcycle",
            "Featured": false,
            "Date": "Tue Jan 09 2018",
            "Rank": null,
            "Name": "Red Motorcycle",
            "Id": "29",
            "__i": {
                "guid": "420208f9-f393-4985-909a-cd011c481cf7",
                "tags": [
                    {
                        "Name": "AUTO_BODY",
                        "Id": "1ff649af-4add-4c30-8c7e-11ebce93660a",
                        "__i": {
                            "guid": "1ff649af-4add-4c30-8c7e-11ebce93660a"
                        }
                    }
                ],
                "acl": 3,
                "model": {
                    "name": "Gallery",
                    "id": "dc64b175-8f16-423e-a333-d44cc12689ba",
                    "version": null
                },
                "auditing": {
                    "createdBy": "3f2841df-7b65-4751-8b83-722cd06538d9",
                    "createdOn": "2018-01-09T17:31:37.24Z",
                    "lastUpdatedBy": "3f2841df-7b65-4751-8b83-722cd06538d9",
                    "lastUpdatedOn": "2018-01-09T17:35:24.924Z"
                }
            }
        },
        {
            "Photos": [
                {
                    "Caption": null,
                    "Rank": null,
                    "Location": null,
                    "Date": null,
                    "Cover": null,
                    "Photo": {
                        "Name": null,
                        "Uri": "/e/locker/id/5a55003c09e1593d009a2f4c",
                        "Description": null,
                        "IsDefault": false,
                        "Type": null,
                        "Id": "5a55003c09e1593d009a2f4c"
                    }
                },
                {
                    "Caption": null,
                    "Rank": null,
                    "Location": null,
                    "Date": null,
                    "Cover": null,
                    "Photo": {
                        "Name": null,
                        "Uri": "/e/locker/id/5a55003709e15932b0c5a04b",
                        "Description": null,
                        "IsDefault": false,
                        "Type": null,
                        "Id": "5a55003709e15932b0c5a04b"
                    }
                },
                {
                    "Caption": null,
                    "Rank": null,
                    "Location": null,
                    "Date": null,
                    "Cover": null,
                    "Photo": {
                        "Name": null,
                        "Uri": "/e/locker/id/5a55004009e1593cfc79fa07",
                        "Description": null,
                        "IsDefault": false,
                        "Type": null,
                        "Id": "5a55004009e1593cfc79fa07"
                    }
                },
                {
                    "Caption": null,
                    "Rank": null,
                    "Location": null,
                    "Date": null,
                    "Cover": null,
                    "Photo": {
                        "Name": null,
                        "Uri": "/e/locker/id/5a55004409e1593cfc79fa09",
                        "Description": null,
                        "IsDefault": false,
                        "Type": null,
                        "Id": "5a55004409e1593cfc79fa09"
                    }
                },
                {
                    "Caption": null,
                    "Rank": null,
                    "Location": null,
                    "Date": null,
                    "Cover": null,
                    "Photo": {
                        "Name": null,
                        "Uri": "/e/locker/id/5a55004809e15930c8339215",
                        "Description": null,
                        "IsDefault": false,
                        "Type": null,
                        "Id": "5a55004809e15930c8339215"
                    }
                },
                {
                    "Caption": null,
                    "Rank": null,
                    "Location": null,
                    "Date": null,
                    "Cover": null,
                    "Photo": {
                        "Name": null,
                        "Uri": "/e/locker/id/5a55004c09e1593cfc79fa0b",
                        "Description": null,
                        "IsDefault": false,
                        "Type": null,
                        "Id": "5a55004c09e1593cfc79fa0b"
                    }
                },
                {
                    "Caption": null,
                    "Rank": null,
                    "Location": null,
                    "Date": null,
                    "Cover": null,
                    "Photo": {
                        "Name": null,
                        "Uri": "/e/locker/id/5a55004f09e1592874ad6552",
                        "Description": null,
                        "IsDefault": false,
                        "Type": null,
                        "Id": "5a55004f09e1592874ad6552"
                    }
                },
                {
                    "Caption": null,
                    "Rank": null,
                    "Location": null,
                    "Date": null,
                    "Cover": null,
                    "Photo": {
                        "Name": null,
                        "Uri": "/e/locker/id/5a55005409e1592874ad6554",
                        "Description": null,
                        "IsDefault": false,
                        "Type": null,
                        "Id": "5a55005409e1592874ad6554"
                    }
                },
                {
                    "Caption": null,
                    "Rank": null,
                    "Location": null,
                    "Date": null,
                    "Cover": null,
                    "Photo": {
                        "Name": null,
                        "Uri": "/e/locker/id/5a55005809e15930c8339217",
                        "Description": null,
                        "IsDefault": false,
                        "Type": null,
                        "Id": "5a55005809e15930c8339217"
                    }
                },
                {
                    "Caption": null,
                    "Rank": null,
                    "Location": null,
                    "Date": null,
                    "Cover": null,
                    "Photo": {
                        "Name": null,
                        "Uri": "/e/locker/id/5a55005b09e1593d743664d7",
                        "Description": null,
                        "IsDefault": false,
                        "Type": null,
                        "Id": "5a55005b09e1593d743664d7"
                    }
                },
                {
                    "Caption": null,
                    "Rank": null,
                    "Location": null,
                    "Date": null,
                    "Cover": null,
                    "Photo": {
                        "Name": null,
                        "Uri": "/e/locker/id/5a55005f09e15930c8339219",
                        "Description": null,
                        "IsDefault": false,
                        "Type": null,
                        "Id": "5a55005f09e15930c8339219"
                    }
                },
                {
                    "Caption": null,
                    "Rank": null,
                    "Location": null,
                    "Date": null,
                    "Cover": null,
                    "Photo": {
                        "Name": null,
                        "Uri": "/e/locker/id/5a55006209e1591788dfab35",
                        "Description": null,
                        "IsDefault": false,
                        "Type": null,
                        "Id": "5a55006209e1591788dfab35"
                    }
                },
                {
                    "Caption": null,
                    "Rank": null,
                    "Location": null,
                    "Date": null,
                    "Cover": null,
                    "Photo": {
                        "Name": null,
                        "Uri": "/e/locker/id/5a55006509e1591788dfab37",
                        "Description": null,
                        "IsDefault": false,
                        "Type": null,
                        "Id": "5a55006509e1591788dfab37"
                    }
                },
                {
                    "Caption": null,
                    "Rank": null,
                    "Location": null,
                    "Date": null,
                    "Cover": null,
                    "Photo": {
                        "Name": null,
                        "Uri": "/e/locker/id/5a55006809e1593d743664d9",
                        "Description": null,
                        "IsDefault": false,
                        "Type": null,
                        "Id": "5a55006809e1593d743664d9"
                    }
                }
            ],
            "Slug": "greentruck",
            "Featured": false,
            "Date": "Tue Jan 09 2018",
            "Rank": null,
            "Name": "Green Truck",
            "Id": "35",
            "__i": {
                "guid": "31ad26c7-0c1a-4e43-b533-68b5fcb8b19e",
                "tags": [
                    {
                        "Name": "VEHICLE_RESTYLING",
                        "Id": "6e04e010-454e-4d3e-8ea6-da6b54c5e00f",
                        "__i": {
                            "guid": "6e04e010-454e-4d3e-8ea6-da6b54c5e00f"
                        }
                    }
                ],
                "acl": 3,
                "model": {
                    "name": "Gallery",
                    "id": "dc64b175-8f16-423e-a333-d44cc12689ba",
                    "version": null
                },
                "auditing": {
                    "createdBy": "3f2841df-7b65-4751-8b83-722cd06538d9",
                    "createdOn": "2018-01-09T17:45:13.479Z",
                    "lastUpdatedBy": "7adf695c-44ab-4ad9-9399-dcbef8d931d0",
                    "lastUpdatedOn": "2018-09-21T13:28:51.683Z"
                }
            }
        },
        {
            "Photos": [
                {
                    "Caption": null,
                    "Rank": null,
                    "Location": null,
                    "Date": null,
                    "Cover": null,
                    "Photo": {
                        "Name": null,
                        "Uri": "/e/locker/id/5a54ff7209e1593b18936cf9",
                        "Description": null,
                        "IsDefault": false,
                        "Type": null,
                        "Id": "5a54ff7209e1593b18936cf9"
                    }
                },
                {
                    "Caption": null,
                    "Rank": null,
                    "Location": null,
                    "Date": null,
                    "Cover": null,
                    "Photo": {
                        "Name": null,
                        "Uri": "/e/locker/id/5a54ff7609e1593b18936cfb",
                        "Description": null,
                        "IsDefault": false,
                        "Type": null,
                        "Id": "5a54ff7609e1593b18936cfb"
                    }
                },
                {
                    "Caption": null,
                    "Rank": null,
                    "Location": null,
                    "Date": null,
                    "Cover": null,
                    "Photo": {
                        "Name": null,
                        "Uri": "/e/locker/id/5a54ff7e09e1593b18936cfd",
                        "Description": null,
                        "IsDefault": false,
                        "Type": null,
                        "Id": "5a54ff7e09e1593b18936cfd"
                    }
                },
                {
                    "Caption": null,
                    "Rank": null,
                    "Location": null,
                    "Date": null,
                    "Cover": null,
                    "Photo": {
                        "Name": null,
                        "Uri": "/e/locker/id/5a54ff8309e15932b0c5a049",
                        "Description": null,
                        "IsDefault": false,
                        "Type": null,
                        "Id": "5a54ff8309e15932b0c5a049"
                    }
                }
            ],
            "Slug": "blackmustang",
            "Featured": false,
            "Date": "Tue Jan 09 2018",
            "Rank": null,
            "Name": "Black Mustang",
            "Id": "33",
            "__i": {
                "guid": "481bc010-476f-4ec9-bb3a-86f9e2a35588",
                "tags": [
                    {
                        "Name": "AUTO_BODY",
                        "Id": "1ff649af-4add-4c30-8c7e-11ebce93660a",
                        "__i": {
                            "guid": "1ff649af-4add-4c30-8c7e-11ebce93660a"
                        }
                    }
                ],
                "acl": 3,
                "model": {
                    "name": "Gallery",
                    "id": "dc64b175-8f16-423e-a333-d44cc12689ba",
                    "version": null
                },
                "auditing": {
                    "createdBy": "3f2841df-7b65-4751-8b83-722cd06538d9",
                    "createdOn": "2018-01-09T17:43:53.559Z",
                    "lastUpdatedBy": "3f2841df-7b65-4751-8b83-722cd06538d9",
                    "lastUpdatedOn": "2018-01-09T17:44:35.339Z"
                }
            }
        },
        {
            "Photos": [
                {
                    "Caption": null,
                    "Rank": null,
                    "Location": null,
                    "Date": null,
                    "Cover": null,
                    "Photo": {
                        "Name": null,
                        "Uri": "/e/locker/id/5a54ff2d09e1593d743664cf",
                        "Description": null,
                        "IsDefault": false,
                        "Type": null,
                        "Id": "5a54ff2d09e1593d743664cf"
                    }
                },
                {
                    "Caption": null,
                    "Rank": null,
                    "Location": null,
                    "Date": null,
                    "Cover": null,
                    "Photo": {
                        "Name": null,
                        "Uri": "/e/locker/id/5a54ff3509e1593d743664d1",
                        "Description": null,
                        "IsDefault": false,
                        "Type": null,
                        "Id": "5a54ff3509e1593d743664d1"
                    }
                },
                {
                    "Caption": null,
                    "Rank": null,
                    "Location": null,
                    "Date": null,
                    "Cover": null,
                    "Photo": {
                        "Name": null,
                        "Uri": "/e/locker/id/5a54ff3909e1592ae05fdb1d",
                        "Description": null,
                        "IsDefault": false,
                        "Type": null,
                        "Id": "5a54ff3909e1592ae05fdb1d"
                    }
                },
                {
                    "Caption": null,
                    "Rank": null,
                    "Location": null,
                    "Date": null,
                    "Cover": null,
                    "Photo": {
                        "Name": null,
                        "Uri": "/e/locker/id/5a54ff3d09e1593d743664d3",
                        "Description": null,
                        "IsDefault": false,
                        "Type": null,
                        "Id": "5a54ff3d09e1593d743664d3"
                    }
                }
            ],
            "Slug": "greencar",
            "Featured": false,
            "Date": "Tue Jan 09 2018",
            "Rank": null,
            "Name": "Green Car",
            "Id": "32",
            "__i": {
                "guid": "6b7d973a-2e7e-4bdc-9702-90e9bb9138a5",
                "tags": [
                    {
                        "Name": "AUTO_BODY",
                        "Id": "1ff649af-4add-4c30-8c7e-11ebce93660a",
                        "__i": {
                            "guid": "1ff649af-4add-4c30-8c7e-11ebce93660a"
                        }
                    }
                ],
                "acl": 3,
                "model": {
                    "name": "Gallery",
                    "id": "dc64b175-8f16-423e-a333-d44cc12689ba",
                    "version": null
                },
                "auditing": {
                    "createdBy": "3f2841df-7b65-4751-8b83-722cd06538d9",
                    "createdOn": "2018-01-09T17:37:56.516Z",
                    "lastUpdatedBy": "3f2841df-7b65-4751-8b83-722cd06538d9",
                    "lastUpdatedOn": "2018-01-09T17:43:31.153Z"
                }
            }
        },
        {
            "Photos": [
                {
                    "Caption": null,
                    "Rank": null,
                    "Location": null,
                    "Date": null,
                    "Cover": null,
                    "Photo": {
                        "Name": null,
                        "Uri": "/e/locker/id/5a54ffbd09e1591788dfab33",
                        "Description": null,
                        "IsDefault": false,
                        "Type": null,
                        "Id": "5a54ffbd09e1591788dfab33"
                    }
                },
                {
                    "Caption": null,
                    "Rank": null,
                    "Location": null,
                    "Date": null,
                    "Cover": null,
                    "Photo": {
                        "Name": null,
                        "Uri": "/e/locker/id/5a54ffce09e1592874ad654e",
                        "Description": null,
                        "IsDefault": false,
                        "Type": null,
                        "Id": "5a54ffce09e1592874ad654e"
                    }
                },
                {
                    "Caption": null,
                    "Rank": null,
                    "Location": null,
                    "Date": null,
                    "Cover": null,
                    "Photo": {
                        "Name": null,
                        "Uri": "/e/locker/id/5a54ffd609e1592874ad6550",
                        "Description": null,
                        "IsDefault": false,
                        "Type": null,
                        "Id": "5a54ffd609e1592874ad6550"
                    }
                },
                {
                    "Caption": null,
                    "Rank": null,
                    "Location": null,
                    "Date": null,
                    "Cover": null,
                    "Photo": {
                        "Name": null,
                        "Uri": "/e/locker/id/5a54ffde09e15913e4f10c8c",
                        "Description": null,
                        "IsDefault": false,
                        "Type": null,
                        "Id": "5a54ffde09e15913e4f10c8c"
                    }
                },
                {
                    "Caption": null,
                    "Rank": null,
                    "Location": null,
                    "Date": null,
                    "Cover": null,
                    "Photo": {
                        "Name": null,
                        "Uri": "/e/locker/id/5a54ffe309e1593d743664d5",
                        "Description": null,
                        "IsDefault": false,
                        "Type": null,
                        "Id": "5a54ffe309e1593d743664d5"
                    }
                },
                {
                    "Caption": null,
                    "Rank": null,
                    "Location": null,
                    "Date": null,
                    "Cover": null,
                    "Photo": {
                        "Name": null,
                        "Uri": "/e/locker/id/5a54ffec09e15913e4f10c8e",
                        "Description": null,
                        "IsDefault": false,
                        "Type": null,
                        "Id": "5a54ffec09e15913e4f10c8e"
                    }
                },
                {
                    "Caption": null,
                    "Rank": null,
                    "Location": null,
                    "Date": null,
                    "Cover": null,
                    "Photo": {
                        "Name": null,
                        "Uri": "/e/locker/id/5a54fff409e15913e4f10c90",
                        "Description": null,
                        "IsDefault": false,
                        "Type": null,
                        "Id": "5a54fff409e15913e4f10c90"
                    }
                },
                {
                    "Caption": null,
                    "Rank": null,
                    "Location": null,
                    "Date": null,
                    "Cover": null,
                    "Photo": {
                        "Name": null,
                        "Uri": "/e/locker/id/5a55000109e1592ae05fdb1f",
                        "Description": null,
                        "IsDefault": false,
                        "Type": null,
                        "Id": "5a55000109e1592ae05fdb1f"
                    }
                },
                {
                    "Caption": null,
                    "Rank": null,
                    "Location": null,
                    "Date": null,
                    "Cover": null,
                    "Photo": {
                        "Name": null,
                        "Uri": "/e/locker/id/5a55000609e1593cfc79f9ff",
                        "Description": null,
                        "IsDefault": false,
                        "Type": null,
                        "Id": "5a55000609e1593cfc79f9ff"
                    }
                },
                {
                    "Caption": null,
                    "Rank": null,
                    "Location": null,
                    "Date": null,
                    "Cover": null,
                    "Photo": {
                        "Name": null,
                        "Uri": "/e/locker/id/5a55000c09e1593cfc79fa01",
                        "Description": null,
                        "IsDefault": false,
                        "Type": null,
                        "Id": "5a55000c09e1593cfc79fa01"
                    }
                },
                {
                    "Caption": null,
                    "Rank": null,
                    "Location": null,
                    "Date": null,
                    "Cover": null,
                    "Photo": {
                        "Name": null,
                        "Uri": "/e/locker/id/5a55001209e1593b18936cff",
                        "Description": null,
                        "IsDefault": false,
                        "Type": null,
                        "Id": "5a55001209e1593b18936cff"
                    }
                },
                {
                    "Caption": null,
                    "Rank": null,
                    "Location": null,
                    "Date": null,
                    "Cover": null,
                    "Photo": {
                        "Name": null,
                        "Uri": "/e/locker/id/5a55001e09e1593cfc79fa03",
                        "Description": null,
                        "IsDefault": false,
                        "Type": null,
                        "Id": "5a55001e09e1593cfc79fa03"
                    }
                },
                {
                    "Caption": null,
                    "Rank": null,
                    "Location": null,
                    "Date": null,
                    "Cover": null,
                    "Photo": {
                        "Name": null,
                        "Uri": "/e/locker/id/5a55002309e1593b18936d01",
                        "Description": null,
                        "IsDefault": false,
                        "Type": null,
                        "Id": "5a55002309e1593b18936d01"
                    }
                },
                {
                    "Caption": null,
                    "Rank": null,
                    "Location": null,
                    "Date": null,
                    "Cover": null,
                    "Photo": {
                        "Name": null,
                        "Uri": "/e/locker/id/5a55002809e1593cfc79fa05",
                        "Description": null,
                        "IsDefault": false,
                        "Type": null,
                        "Id": "5a55002809e1593cfc79fa05"
                    }
                }
            ],
            "Slug": "bluetruck",
            "Featured": false,
            "Date": "Tue Jan 09 2018",
            "Rank": null,
            "Name": "Blue Truck",
            "Id": "34",
            "__i": {
                "guid": "891d9fdc-a56a-49f4-b96e-892c603a2bf3",
                "tags": [
                    {
                        "Name": "AUTO_BODY",
                        "Id": "1ff649af-4add-4c30-8c7e-11ebce93660a",
                        "__i": {
                            "guid": "1ff649af-4add-4c30-8c7e-11ebce93660a"
                        }
                    }
                ],
                "acl": 3,
                "model": {
                    "name": "Gallery",
                    "id": "dc64b175-8f16-423e-a333-d44cc12689ba",
                    "version": null
                },
                "auditing": {
                    "createdBy": "3f2841df-7b65-4751-8b83-722cd06538d9",
                    "createdOn": "2018-01-09T17:44:56.933Z",
                    "lastUpdatedBy": "3f2841df-7b65-4751-8b83-722cd06538d9",
                    "lastUpdatedOn": "2018-01-09T17:47:21.087Z"
                }
            }
        },
        {
            "Photos": [
                {
                    "Caption": null,
                    "Rank": null,
                    "Location": null,
                    "Date": null,
                    "Cover": null,
                    "Photo": {
                        "Name": null,
                        "Uri": "/e/locker/id/5a54fd8d09e15913e4f10c80",
                        "Description": null,
                        "IsDefault": false,
                        "Type": null,
                        "Id": "5a54fd8d09e15913e4f10c80"
                    }
                },
                {
                    "Caption": null,
                    "Rank": null,
                    "Location": null,
                    "Date": null,
                    "Cover": null,
                    "Photo": {
                        "Name": null,
                        "Uri": "/e/locker/id/5a54fd9109e1593d743664cd",
                        "Description": null,
                        "IsDefault": false,
                        "Type": null,
                        "Id": "5a54fd9109e1593d743664cd"
                    }
                },
                {
                    "Caption": null,
                    "Rank": null,
                    "Location": null,
                    "Date": null,
                    "Cover": null,
                    "Photo": {
                        "Name": null,
                        "Uri": "/e/locker/id/5a54fd9409e15913e4f10c82",
                        "Description": null,
                        "IsDefault": false,
                        "Type": null,
                        "Id": "5a54fd9409e15913e4f10c82"
                    }
                },
                {
                    "Caption": null,
                    "Rank": null,
                    "Location": null,
                    "Date": null,
                    "Cover": null,
                    "Photo": {
                        "Name": null,
                        "Uri": "/e/locker/id/5a54fd9809e15913e4f10c84",
                        "Description": null,
                        "IsDefault": false,
                        "Type": null,
                        "Id": "5a54fd9809e15913e4f10c84"
                    }
                }
            ],
            "Slug": "blackmclaren",
            "Featured": false,
            "Date": "Tue Jan 09 2018",
            "Rank": null,
            "Name": "Black Mclaren",
            "Id": "30",
            "__i": {
                "guid": "9b49231b-7364-4164-b22d-8b5809a1d725",
                "tags": [
                    {
                        "Name": "AUTO_BODY",
                        "Id": "1ff649af-4add-4c30-8c7e-11ebce93660a",
                        "__i": {
                            "guid": "1ff649af-4add-4c30-8c7e-11ebce93660a"
                        }
                    }
                ],
                "acl": 3,
                "model": {
                    "name": "Gallery",
                    "id": "dc64b175-8f16-423e-a333-d44cc12689ba",
                    "version": null
                },
                "auditing": {
                    "createdBy": "3f2841df-7b65-4751-8b83-722cd06538d9",
                    "createdOn": "2018-01-09T17:35:58.439Z",
                    "lastUpdatedBy": "3f2841df-7b65-4751-8b83-722cd06538d9",
                    "lastUpdatedOn": "2018-01-09T17:37:02.454Z"
                }
            }
        },
        {
            "Photos": [
                {
                    "Caption": null,
                    "Rank": null,
                    "Location": null,
                    "Date": null,
                    "Cover": null,
                    "Photo": {
                        "Name": null,
                        "Uri": "/e/locker/id/5a54fe1d09e1591788dfab27",
                        "Description": null,
                        "IsDefault": false,
                        "Type": null,
                        "Id": "5a54fe1d09e1591788dfab27"
                    }
                },
                {
                    "Caption": null,
                    "Rank": null,
                    "Location": null,
                    "Date": null,
                    "Cover": null,
                    "Photo": {
                        "Name": null,
                        "Uri": "/e/locker/id/5a54fe2009e1591788dfab29",
                        "Description": null,
                        "IsDefault": false,
                        "Type": null,
                        "Id": "5a54fe2009e1591788dfab29"
                    }
                },
                {
                    "Caption": null,
                    "Rank": null,
                    "Location": null,
                    "Date": null,
                    "Cover": null,
                    "Photo": {
                        "Name": null,
                        "Uri": "/e/locker/id/5a54fe2409e1591788dfab2b",
                        "Description": null,
                        "IsDefault": false,
                        "Type": null,
                        "Id": "5a54fe2409e1591788dfab2b"
                    }
                },
                {
                    "Caption": null,
                    "Rank": null,
                    "Location": null,
                    "Date": null,
                    "Cover": null,
                    "Photo": {
                        "Name": null,
                        "Uri": "/e/locker/id/5a54feb109e1593d009a2f4a",
                        "Description": null,
                        "IsDefault": false,
                        "Type": null,
                        "Id": "5a54feb109e1593d009a2f4a"
                    }
                },
                {
                    "Caption": null,
                    "Rank": null,
                    "Location": null,
                    "Date": null,
                    "Cover": null,
                    "Photo": {
                        "Name": null,
                        "Uri": "/e/locker/id/5a54fe2809e1591788dfab2d",
                        "Description": null,
                        "IsDefault": false,
                        "Type": null,
                        "Id": "5a54fe2809e1591788dfab2d"
                    }
                },
                {
                    "Caption": null,
                    "Rank": null,
                    "Location": null,
                    "Date": null,
                    "Cover": null,
                    "Photo": {
                        "Name": null,
                        "Uri": "/e/locker/id/5a54fe2e09e1591788dfab2f",
                        "Description": null,
                        "IsDefault": false,
                        "Type": null,
                        "Id": "5a54fe2e09e1591788dfab2f"
                    }
                },
                {
                    "Caption": null,
                    "Rank": null,
                    "Location": null,
                    "Date": null,
                    "Cover": null,
                    "Photo": {
                        "Name": null,
                        "Uri": "/e/locker/id/5a54fe4109e15913e4f10c88",
                        "Description": null,
                        "IsDefault": false,
                        "Type": null,
                        "Id": "5a54fe4109e15913e4f10c88"
                    }
                }
            ],
            "Slug": "browncar",
            "Featured": false,
            "Date": "Tue Jan 09 2018",
            "Rank": null,
            "Name": "Brown Car",
            "Id": "31",
            "__i": {
                "guid": "c46a7678-b068-4849-bf81-1b8eeb8c9079",
                "tags": [
                    {
                        "Name": "AUTO_BODY",
                        "Id": "1ff649af-4add-4c30-8c7e-11ebce93660a",
                        "__i": {
                            "guid": "1ff649af-4add-4c30-8c7e-11ebce93660a"
                        }
                    }
                ],
                "acl": 3,
                "model": {
                    "name": "Gallery",
                    "id": "dc64b175-8f16-423e-a333-d44cc12689ba",
                    "version": null
                },
                "auditing": {
                    "createdBy": "3f2841df-7b65-4751-8b83-722cd06538d9",
                    "createdOn": "2018-01-09T17:37:28.141Z",
                    "lastUpdatedBy": "3f2841df-7b65-4751-8b83-722cd06538d9",
                    "lastUpdatedOn": "2018-01-09T17:42:13.154Z"
                }
            }
        }
    ],
    "Date": "2017-12-01T13:13:05.54Z",
    "Id": 2,
    "Name": "Gallery Page",
    "__i": {
        "guid": "88c07f9e-4627-4fd6-bfd6-b749c87b1b4a",
        "tags": [],
        "acl": 0,
        "model": {
            "name": "GalleryGroup",
            "id": "a6572aae-54f3-42ab-97bd-db49658700df",
            "version": null
        },
        "auditing": {
            "createdBy": "6cbd7180-5c27-4cbd-af7d-16d1dd4ada32",
            "createdOn": "2017-12-01T13:12:57.2910000Z",
            "lastUpdatedBy": "7ff39a31-6c24-49f7-b857-eb2e51765b2a",
            "lastUpdatedOn": "2018-09-21T16:02:35.7600000Z"
        }
    }
};

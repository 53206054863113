import React, { } from 'react';
import { Link } from 'react-router-dom';

import Header from '../Header';

function ContactUs() {
  return (
    <div className="ContactUs">
      <div className="angle-feature subpage">
        <Header title="Contact Us"/>
      </div>
      <div className="container padded">
        <div className="row">
          <div className="col-sm-12">
            <h4>Innovative Autoworks is located on Lake Drive in Wexford, less than a mile from the intersection of Routes 19 and 910.</h4>
          </div>
          <div className="row">
            <div className="col-sm-7">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12114.234102437526!2d-80.06252409528518!3d40.617563051221815!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x883489827e603417%3A0xcdbe29d36c35310!2sInnovative+Autoworks!5e0!3m2!1sen!2sus!4v1506955108922" width="100%" height="400" className="map-iframe" ></iframe>                    </div>
            <div className="col-sm-5 contact-info">
              <h3>Phone</h3>
              <p><i className="fa fa-fw fa-phone"></i>&nbsp;<Link to="tel:1{724-933-5656}">{'724-933-5656'}</Link></p>
              <h3>Email</h3>
              <p><i className="fa fa-fw fa-envelope"></i>&nbsp;<Link to="mailto:{sales@innovativeauto.works}">{'sales@innovativeauto.works'}</Link></p>
              <h3>Hours</h3>
              <table>
                <tr>
                  <td>Mon:</td>
                  <td> {'8:00 am - 5:30 pm'}</td>
                </tr>
                <tr>
                  <td>Tue:</td>
                  <td>{'8:00 am - 5:30 pm'}</td>
                </tr>
                <tr>
                  <td>Wed:</td>
                  <td>{'8:00 am - 5:30 pm'}</td>
                </tr>
                <tr>
                  <td>Thu:</td>
                  <td>{'8:00 am - 5:30 pm'}</td>
                </tr>
                <tr>
                  <td>Fri:</td>
                  <td>{'8:00 am - 5:30 pm'}</td>
                </tr>
                <tr>
                  <td>Sat:</td>
                  <td>{'by Appointment'}</td>
                </tr>
                <tr>
                  <td>Sun:</td>
                  <td>{'Closed'}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;

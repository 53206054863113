export const apiRoot = () => {
    const theURL:string = document.location.href;

    if ( theURL.includes('localhost') ) {
        return '/apidev';
    }
    else if ( theURL.includes('dev.innovativeauto.works') ) {
        return '/apidev';
    }
    else if ( theURL.includes('innovativeauto.works') ) {
        return '/apiprod';
    }
}


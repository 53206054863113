import React, { } from 'react';
import { Link } from 'react-router-dom';

import Header from '../Header';
import svcPowderCoating from '../assets/img/svc-powdercoating.jpg';

function Powdercoating() {
  return (
    <div className="Powdercoating">
      <div className="angle-feature subpage">
        <Header title="Powdercoating" navOn="services" />
      </div>
      <div className="container padded">
        <div className="row">
          <div className="col-sm-4">
            <p>
              <img src={svcPowderCoating} className="section-photo" />
            </p>
          </div>
          <div className="col-sm-8">
            <h3>Automotive Services</h3>
            <p>Powder coating can be a great way to finish/re-finish wheels, engine parts, underhood components, and
              anything else that may be exposed to extreme elements. Since we work on custom vehicles everyday we know
              what to mask, plug, and clean so the parts go on just as they came off. Only looking far better than
              before. Contact our sales team today to see what we can do for you.</p>

            <h3>Motorcycle Services</h3>
            <p>Powder coating can be a great way to finish/re-finish wheels, engine parts, underhood components, and
              anything else that may be exposed to extreme elements. Since we work on custom vehicles everyday we know
              what to mask, plug, and clean so the parts go on just as they came off. Only looking far better than
              before. Powder coating can be a great way to finish/re-finish wheels, engine parts, underhood
              components, and anything else that may be exposed to extreme elements. Since we work on custom vehicles
              everyday we know what to mask, plug, and clean so the parts go on just as they came off. Only looking
              far better than before. Contact our sales team today to see what we can do for you.</p>

            <h3>General Powdercoating</h3>
            <p>We do more than just automotive based powder coating. We can handle pretty much anything thrown at us as
              long as it fits in our 8'x8'x8' oven. We can do work for the general consumer or contract jobs in the
              industrial market. Contact our sales team today to see what we can do for you.</p>

          </div>
        </div>
      </div>
    </div>
  );
}

export default Powdercoating;

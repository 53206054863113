import React, { } from 'react';
import { Link } from 'react-router-dom';

import Header from '../Header';
import restyling from '../assets/img/restyling.png';

function Restyling() {
  return (
    <div className="Restyling">
      <div className="angle-feature subpage">
        <Header title="Vehicle Restyling" navOn="services" />
      </div>
      <div className="container padded">
        <div className="row">
          <div className="col-sm-4">
            <p>
              <img src={restyling} className="section-photo" />
            </p>
          </div>
          <div className="col-sm-8">
            <h3>Performance</h3>
            <p>Is your car not putting the smile on your face when you hit the gas like it used to? Let us put that
              smile back bigger than ever! From air intakes and exhaust to superchargers and turbos we can tune your
              car whatever it needs to make it better than ever.</p>
            <p>Now if you own a truck don't think we're leaving you out, we offer a full line of diesel performance
              products to get your truck running like it should. We also can handle driveline upgrades. If you have a
              lifted truck and need help moving those new big tires we can swap the gears out to get it rolling like
              it used to.</p>
            <p>Let us know how we can help!</p>

            <h3>Custom Paint</h3>
            <p>Starting with some of the best painters in Pittsburgh, our freshly renovated paint facility, and
              utilizing the finest products in the refinishing community from PPG and 3M.You're in good hands. If you
              want a gloss or satin finish. Factory color or go all out with candy paint, they can do it all.Your
              imagination is the only limit. Our skill makes it happen.
            </p>

            <h3>Complete Custom Projects</h3>
            <p>Innovative Autoworks is Pittsburgh's one stop shop for complete customs builds. Classic or New. Car or
              Truck. We feel that if you can dream it, we can build it. Our sales associates, installers, painters and
              fabricators work closely with the customer every step of the way to make sure they get what they want
              and their dream ride is delivered to them. It doesn't matter if you start with the shell of a car or a
              brand new truck off the lot, we can start with what you got. For more information or to start creating
              your dream ride give one of our helpful sales associates a call and let them help you begin your
              process.
            </p>

            <h3>Car and Truck Accessories</h3>
            <p>Did you just get a new car and want to protect it with new floor mats and window tint? Do you use your
              vehicle for work, and need to keep it safe and clean? Innovative Autoworks has a wide variety of car and
              truck accessories to dress up your vehicle and improve its functionality. We have truck accessories such
              as full bumpers, grill guards, running boards, nerf bars, power steps, bed rails, bedliners, tool boxes,
              and more! Anything you can think of add onto your vehicle, we have it here.
            </p>

            <h3>Fabrication</h3>
            <p>Once your done searching far and wide for that special part that just doesn't exist, let us build it for
              you. If you can't find that set of headers or exhaust for your old hot rod, we can handle that for you.
              If you need your fenders modified on your truck because the rub with the giant tires you put on it, we
              can handle that for you. If you need a harness bar and back seat removed in your race car, we can handle
              that for you. If no one makes an air suspension set up for you car, we can handle that for you.
              Basically what we're saying is that if you need something built for your car, we can.
            </p>

            <h3>Mobile Audio</h3>
            <p>Music can really set the tone for an experience, so why not let the soundtrack to your life be heard loud
              and clear? From simple to extreme let us upgrade your car to play it the way you want to hear it.</p>

          </div>
        </div>
      </div>
    </div>
  );
}

export default Restyling;

import React, { } from 'react';
import { Link } from "react-router-dom";

import Header from '../Header';
import svcAutoBody from '../assets/img/svc-autobody.jpg';
import svcRestyling from '../assets/img/svc-restyling.jpg';
import svcPowderCoating from '../assets/img/svc-powdercoating.jpg';
import svcElectronics from '../assets/img/svc-electronics.jpg';

function Services() {
    return (
        <div className="Services">
            <div className="angle-feature subpage">
                <Header title="Services" navOn="services" />
            </div>
            <div className="container padded">
                <div className="row">
                    <div className="col-sm-12">
                        <h4>Innovative Autoworks offers a full range of collision and customization services for your car or truck.
                            All services are performed on site at our state-of-the-art shop in Wexford, PA.</h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <Link to="/autobody" className="feature-block">
                            <h1>Auto Body</h1>
                            <div className="img-hidden">
                                <img src={svcAutoBody} className="img-stretch" />
                            </div>
                            <div className="tag">Collision Repair</div>
                            <div className="tag">Restoration</div>
                            <div className="tag">Insurance Claims</div>
                        </Link>
                    </div>
                    <div className="col-sm-6">
                        <Link to="/restyling" className="feature-block">
                            <h1>Vehicle Restyling</h1>
                            <div className="img-hidden">
                                <img src={svcRestyling} className="img-stretch" />
                            </div>
                            <div className="tag">Performance</div>
                            <div className="tag">Custom Paint</div>
                            <div className="tag">Mobile Audio</div>
                            <div className="tag">Complete Custom Projects</div>
                            <div className="tag">Fabrication</div>
                            <div className="tag">Car and Truck Accessories</div>

                        </Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <Link to="/powdercoating" className="feature-block">
                            <h1>Powdercoating</h1>
                            <div className="img-hidden">
                                <img src={svcPowderCoating} className="img-stretch" />
                            </div>
                            <div className="tag">Automotive Services</div>
                            <div className="tag">Motorcycle Services</div>
                            <div className="tag">General Powdercoating</div>
                        </Link>
                    </div>
                    <div className="col-sm-6">
                        <Link to="/suspension" className="feature-block">
                            <h1>Suspension, Wheels, Tires</h1>
                            <div className="img-hidden">
                                <img src={svcElectronics} className="img-stretch" />
                            </div>
                            <div className="tag">Suspension Lifts</div>
                            <div className="tag">Wheels</div>
                            <div className="tag">Air Suspension</div>
                            <div className="tag">Tires</div>
                            <div className="tag">Suspension Lowering</div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Services;

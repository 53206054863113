import React from 'react';
import { Link } from "react-router-dom";

import logoFooter from './assets/img/logo-footer.png';

function Footer ( props:any ) {
    const { contactInfo } = props;

    const year = new Date().getFullYear();

    return (
        <footer>
            <div className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <img src={logoFooter} />
                            <div className="address">140 Lake Drive, <div>#102,
                            Wexford, PA  15090</div></div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4">
                            <b>phone:</b> <Link to={"tel:17249335656"}>724-933-5656</Link>
                        </div>
                        <div className="col-sm-4">
                            <b>email:</b> <Link to={"mailto:{sales@innovativeauto.works}"}>sales@innovativeauto.works</Link>
                        </div>
                        <div className="col-sm-4">
                            <b>hours:</b> Mon-Fri 8:00am - 5:30pm
                        </div>
                    </div>
                </div>
                <div className="copyright">
                    Copyright &copy; {year} Innovative Autoworks. All Rights Reserved. <Link to="https://www.buildinmotion.com/?utm_source=innovative-autoworks&utm_medium=site-footer&utm_campaign=client-referral">Powered by Build in Motion</Link>
                </div>
            </div>
        </footer>
    );
}

export default Footer;

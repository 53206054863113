import React, {} from 'react';
import { Link } from "react-router-dom";

import Header from '../Header';
import logoWideBlack from '../assets/img/logo-wide-black.png';
import sepLines from '../assets/img/sep-lines.png';
import tagLine from '../assets/img/tagline.png';
import Portfolio from './Portfolio';

import sparks from '../assets/img/home-sparks.png';
import tj from '../assets/img/home-tj.png';
import paint from '../assets/img/home-paint.png';

function Home() {
    return (
        <div className="Home">

            <div className="angle-feature">
                <Header title="Home" navOn="home" />
            </div>

            <div className="container padded">
                <div className="row">
                    <div className="col-sm-5">
                        <p><img src={logoWideBlack} className="img-responsive logo-left"/></p>
                        <p><img src={sepLines}/></p>

                        <h2>Our Work</h2>
                        <p>We have been the area's premier one stop vehicle restyling and collision repair facility
                            since 2011. We specialize in collision repair, performance upgrades, and any auto body
                            related services. The company was founded provide enthusiasts with one shop that can fulfill
                            all of their automotive needs without traveling to numerous other shops to achieve what they
                            are looking for.</p>
                        <p><b>We offer these services and more at our shop in Wexford, PA:</b></p>

                        <Link to="autobody" className="feature-service">
                            <h1>Auto Body Repair</h1>
                            <h2>Collision Repair, Restoration, Insurance Repair</h2>
                        </Link>

                        <Link to="restyling" className="feature-service">
                            <h1>Vehicle Restyling</h1>
                            <h2>Full Custom Builds, Performance upgrades, Custom Fabrication, Audio, Truck Accessories,
                                Custom Paint</h2>
                        </Link>

                        <Link to="suspension" className="feature-service">
                            <h1>Suspension, Wheels & Tires</h1>
                            <h2>Lift Kits, Lowering Kits, Air Suspension, Wheels, Tires</h2>
                        </Link>

                        <Link to="powdercoating" className="feature-service">
                            <h1>Powdercoating</h1>
                            <h2>Automotive Services, Motorcycle Services, General Powder Coating Services</h2>
                        </Link>

                    </div>
                    <div className="col-sm-7 home-mosaic hidden-xs">
                        <img src={sparks} />
                        <img src={paint} />
                        <img src={tj} />

                    </div>
                </div>
            </div>
            <Portfolio/>
        </div>
    );
}

export default Home;

import React, { } from 'react';
import { Link } from 'react-router-dom';

import Header from '../Header';
import svcAutoBody from '../assets/img/svc-autobody.jpg';

function AutoBody() {
  return (
    <div className="AutoBody">
            <div className="angle-feature subpage">
              <Header title="Auto Body Repair" />
            </div>
      <div className="container padded">
        <div className="row">
          <div className="col-sm-3">
            <p>
              <img src={svcAutoBody} className="section-photo" />
            </p>
          </div>
          <div className="col-sm-9">

            <h3>Collision Repair</h3>
            <p>Starting with our licensed estimating department,Innovative Autoworks supplies advice, guidance and
              assistance throughout
              the entire Insurance Claims, Repair and/or Restoration process. Your questions will be answered. Your
              concerns will be
              addressed with sincerity and honesty.Innovative Autoworks is more then just another “body shop”. We
              treat each vehicle
              as our own so you can be assured a proper repair.
            </p>

            <h3>Restoration</h3>
            <p>Your favorite ride can rise again. Let us bring it back in pristine, showroom condition just as you
              remember it. Or if you
              love the car but are tired of old antiquated technology we can give it an updated classNameic look with all
              the comfort, safety,
              performance and accessories of a modern vehicle. We supply the design, components and craftsmanship …
              You just bring your
              imagination.
            </p>

            <h3>Insurance Claims</h3>
            <p>We accept all insurance and we will repair your vehicle back to its original condition. We will handle
              the claim with the
              insurance company during the repair. Innovative Autoworks strives to provide our customers with
              outstanding service and
              complete confidence in our work.
            </p>
          </div>
        </div>
      </div>

    </div>
  );
}

export default AutoBody;

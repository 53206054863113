import React, { Component, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import Header from '../Header';
import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

import redMotorcycle1 from '../assets/img/gallery/red-motorcycle/red-motorcycle-1.jpeg';
import redMotorcycle2 from '../assets/img/gallery/red-motorcycle/red-motorcycle-2.jpeg';
import redMotorcycle3 from '../assets/img/gallery/red-motorcycle/red-motorcycle-3.jpeg';
import redMotorcycle4 from '../assets/img/gallery/red-motorcycle/red-motorcycle-4.jpeg';
import redMotorcycle5 from '../assets/img/gallery/red-motorcycle/red-motorcycle-5.jpeg';
import redMotorcycle6 from '../assets/img/gallery/red-motorcycle/red-motorcycle-6.jpeg';

import greenTruck1 from '../assets/img/gallery/green-truck/green-truck-1.jpeg';
import greenTruck2 from '../assets/img/gallery/green-truck/green-truck-2.jpeg';
import greenTruck3 from '../assets/img/gallery/green-truck/green-truck-3.jpeg';
import greenTruck4 from '../assets/img/gallery/green-truck/green-truck-4.jpeg';
import greenTruck5 from '../assets/img/gallery/green-truck/green-truck-5.jpeg';
import greenTruck6 from '../assets/img/gallery/green-truck/green-truck-6.jpeg';
import greenTruck7 from '../assets/img/gallery/green-truck/green-truck-7.jpeg';
import greenTruck8 from '../assets/img/gallery/green-truck/green-truck-8.jpeg';
import greenTruck9 from '../assets/img/gallery/green-truck/green-truck-9.jpeg';
import greenTruck10 from '../assets/img/gallery/green-truck/green-truck-10.jpeg';
import greenTruck11 from '../assets/img/gallery/green-truck/green-truck-11.jpeg';
import greenTruck12 from '../assets/img/gallery/green-truck/green-truck-12.jpeg';
import greenTruck13 from '../assets/img/gallery/green-truck/green-truck-13.jpeg';
import greenTruck14 from '../assets/img/gallery/green-truck/green-truck-14.jpeg';

import blackCar1 from '../assets/img/gallery/black-car/black-car-1.jpeg';
import blackCar2 from '../assets/img/gallery/black-car/black-car-2.jpeg';
import blackCar3 from '../assets/img/gallery/black-car/black-car-3.jpeg';
import blackCar4 from '../assets/img/gallery/black-car/black-car-4.jpeg';

import greenCar1 from '../assets/img/gallery/green-car/green-car-1.jpeg';
import greenCar2 from '../assets/img/gallery/green-car/green-car-2.jpeg';
import greenCar3 from '../assets/img/gallery/green-car/green-car-3.jpeg';
import greenCar4 from '../assets/img/gallery/green-car/green-car-4.jpeg';

import blueTruck1 from '../assets/img/gallery/blue-truck/blue-truck-1.jpeg';
import blueTruck2 from '../assets/img/gallery/blue-truck/blue-truck-2.jpeg';
import blueTruck3 from '../assets/img/gallery/blue-truck/blue-truck-3.jpeg';
import blueTruck4 from '../assets/img/gallery/blue-truck/blue-truck-4.jpeg';
import blueTruck5 from '../assets/img/gallery/blue-truck/blue-truck-5.jpeg';
import blueTruck6 from '../assets/img/gallery/blue-truck/blue-truck-6.jpeg';
import blueTruck7 from '../assets/img/gallery/blue-truck/blue-truck-7.jpeg';
import blueTruck8 from '../assets/img/gallery/blue-truck/blue-truck-8.jpeg';
import blueTruck9 from '../assets/img/gallery/blue-truck/blue-truck-9.jpeg';
import blueTruck10 from '../assets/img/gallery/blue-truck/blue-truck-10.jpeg';
import blueTruck11 from '../assets/img/gallery/blue-truck/blue-truck-11.jpeg';
import blueTruck12 from '../assets/img/gallery/blue-truck/blue-truck-12.jpeg';
import blueTruck13 from '../assets/img/gallery/blue-truck/blue-truck-13.jpeg';
import blueTruck14 from '../assets/img/gallery/blue-truck/blue-truck-14.jpeg';

import blackMclaren1 from '../assets/img/gallery/black-mclaren/black-mclaren-1.jpeg';
import blackMclaren2 from '../assets/img/gallery/black-mclaren/black-mclaren-2.jpeg';
import blackMclaren3 from '../assets/img/gallery/black-mclaren/black-mclaren-3.jpeg';
import blackMclaren4 from '../assets/img/gallery/black-mclaren/black-mclaren-4.jpeg';

import brownCar1 from '../assets/img/gallery/brown-car/brown-car-1.jpeg';
import brownCar2 from '../assets/img/gallery/brown-car/brown-car-2.jpeg';
import brownCar3 from '../assets/img/gallery/brown-car/brown-car-3.jpeg';
import brownCar4 from '../assets/img/gallery/brown-car/brown-car-4.jpeg';
import brownCar5 from '../assets/img/gallery/brown-car/brown-car-5.jpeg';
import brownCar6 from '../assets/img/gallery/brown-car/brown-car-6.jpeg';
import brownCar7 from '../assets/img/gallery/brown-car/brown-car-7.jpeg';

const Gallery = () => {

    //console.log('Gallery path: ', "https:/" + galleries.Galleries[0].Photos[0].Photo.Uri);

    return (
        <div className="gallery">
            <div className="angle-feature subpage">
                <Header title="Gallery"/>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <br/>
                        <h4>The following is a sample of the quality auto body repair and vehicle restyling services
                            offered by Innovative Autoworks.
                        </h4>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12" id="project-gallery">
                        <LightGallery
                            speed={500}
                            plugins={[lgThumbnail, lgZoom]}
                            hideControlOnEnd={true}
                            loop={false}
                        >
                            <Link to={redMotorcycle1} className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                                <img alt="gallery1" src={redMotorcycle1} style={{ width: "100%", height: "220px" }}/>
                            </Link>
                            <Link to={redMotorcycle2} hidden={true}>
                                <img alt="gallery1" src={redMotorcycle2} style={{ maxWidth: "250px" }}/>
                            </Link>
                            <Link to={redMotorcycle3} hidden={true}>
                                <img alt="gallery1" src={redMotorcycle3} style={{ maxWidth: "250px" }}/>
                            </Link>
                            <Link to={redMotorcycle4} hidden={true}>
                                <img alt="gallery1" src={redMotorcycle4} style={{ maxWidth: "250px" }}/>
                            </Link>
                            <Link to={redMotorcycle5} hidden={true}>
                                <img alt="gallery1" src={redMotorcycle5} style={{ maxWidth: "250px" }}/>
                            </Link>
                            <Link to={redMotorcycle6} hidden={true}>
                                <img alt="gallery1" src={redMotorcycle6} style={{ maxWidth: "250px" }}/>
                            </Link>
                        </LightGallery>

                        <LightGallery
                            speed={500}
                            plugins={[lgThumbnail, lgZoom]}
                            hideControlOnEnd={true}
                            loop={false}
                        >
                            <Link to={greenTruck1} className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                                <img alt="gallery2" src={greenTruck1} style={{ width: "100%", height: "220px" }}/>
                            </Link>
                            <Link to={greenTruck2} hidden={true}>
                                <img alt="gallery2" src={greenTruck2} style={{ maxWidth: "250px" }}/>
                            </Link>
                            <Link to={greenTruck3} hidden={true}>
                                <img alt="gallery2" src={greenTruck3} style={{ maxWidth: "250px" }}/>
                            </Link>
                            <Link to={greenTruck4} hidden={true}>
                                <img alt="gallery2" src={greenTruck4} style={{ maxWidth: "250px" }}/>
                            </Link>
                            <Link to={greenTruck5} hidden={true}>
                                <img alt="gallery2" src={greenTruck5} style={{ maxWidth: "250px" }}/>
                            </Link>
                            <Link to={greenTruck6} hidden={true}>
                                <img alt="gallery2" src={greenTruck6} style={{ maxWidth: "250px" }}/>
                            </Link>
                            <Link to={greenTruck7} hidden={true}>
                                <img alt="gallery2" src={greenTruck7} style={{ maxWidth: "250px" }}/>
                            </Link>
                            <Link to={greenTruck8} hidden={true}>
                                <img alt="gallery2" src={greenTruck8} style={{ maxWidth: "250px" }}/>
                            </Link>
                            <Link to={greenTruck9} hidden={true}>
                                <img alt="gallery2" src={greenTruck9} style={{ maxWidth: "250px" }}/>
                            </Link>
                            <Link to={greenTruck10} hidden={true}>
                                <img alt="gallery2" src={greenTruck10} style={{ maxWidth: "250px" }}/>
                            </Link>
                            <Link to={greenTruck11} hidden={true}>
                                <img alt="gallery2" src={greenTruck11} style={{ maxWidth: "250px" }}/>
                            </Link>
                            <Link to={greenTruck12} hidden={true}>
                                <img alt="gallery2" src={greenTruck12} style={{ maxWidth: "250px" }}/>
                            </Link>
                            <Link to={greenTruck13} hidden={true}>
                                <img alt="gallery2" src={greenTruck13} style={{ maxWidth: "250px" }}/>
                            </Link>
                            <Link to={greenTruck14} hidden={true}>
                                <img alt="gallery2" src={greenTruck14} style={{ maxWidth: "250px" }}/>
                            </Link>
                        </LightGallery>

                        <LightGallery
                            speed={500}
                            plugins={[lgThumbnail, lgZoom]}
                            hideControlOnEnd={true}
                            loop={false}
                        >
                            <Link to={blackCar1} className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                                <img alt="gallery3" src={blackCar1} style={{ width: "100%", height: "220px" }}/>
                            </Link>
                            <Link to={blackCar2} className="gallery" hidden={true}>
                                <img alt="gallery3" src={blackCar2} style={{ maxWidth: "250px" }}/>
                            </Link>
                            <Link to={blackCar3} className="gallery" hidden={true}>
                                <img alt="gallery3" src={blackCar3} style={{ maxWidth: "250px" }}/>
                            </Link>
                            <Link to={blackCar4} className="gallery" hidden={true}>
                                <img alt="gallery3" src={blackCar4} style={{ maxWidth: "250px" }}/>
                            </Link>
                        </LightGallery>

                        <LightGallery
                            speed={500}
                            plugins={[lgThumbnail, lgZoom]}
                            hideControlOnEnd={true}
                            loop={false}
                        >
                            <Link to={greenCar1} className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                                <img alt="gallery4" src={greenCar1} style={{ width: "100%", height: "220px" }}/>
                            </Link>
                            <Link to={greenCar2} className="gallery-item" hidden={true}>
                                <img alt="gallery4" src={greenCar2} style={{ maxWidth: "250px" }}/>
                            </Link>
                            <Link to={greenCar3} className="gallery-item" hidden={true}>
                                <img alt="gallery4" src={greenCar3} style={{ maxWidth: "250px" }}/>
                            </Link>
                            <Link to={greenCar4} className="gallery-item" hidden={true}>
                                <img alt="gallery4" src={greenCar4} style={{ maxWidth: "250px" }}/>
                            </Link>
                        </LightGallery>

                        <LightGallery
                            speed={500}
                            plugins={[lgThumbnail, lgZoom]}
                            hideControlOnEnd={true}
                            loop={false}
                        >
                            <Link to={blueTruck1} className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                                <img alt="gallery5" src={blueTruck1} style={{ width: "100%", height: "220px" }}/>
                            </Link>
                            <Link to={blueTruck2} className="gallery-item" hidden={true}>
                                <img alt="gallery5" src={blueTruck2} style={{ maxWidth: "250px" }}/>
                            </Link>
                            <Link to={blueTruck3} className="gallery-item" hidden={true}>
                                <img alt="gallery5" src={blueTruck3} style={{ maxWidth: "250px" }}/>
                            </Link>
                            <Link to={blueTruck4} className="gallery-item" hidden={true}>
                                <img alt="gallery5" src={blueTruck4} style={{ maxWidth: "250px" }}/>
                            </Link>
                            <Link to={blueTruck5} className="gallery-item" hidden={true}>
                                <img alt="gallery5" src={blueTruck5} style={{ maxWidth: "250px" }}/>
                            </Link>
                            <Link to={blueTruck6} className="gallery-item" hidden={true}>
                                <img alt="gallery5" src={blueTruck6} style={{ maxWidth: "250px" }}/>
                            </Link>
                            <Link to={blueTruck7} className="gallery-item" hidden={true}>
                                <img alt="gallery5" src={blueTruck7} style={{ maxWidth: "250px" }}/>
                            </Link>
                            <Link to={blueTruck8} className="gallery-item" hidden={true}>
                                <img alt="gallery5" src={blueTruck8} style={{ maxWidth: "250px" }}/>
                            </Link>
                            <Link to={blueTruck9} className="gallery-item" hidden={true}>
                                <img alt="gallery5" src={blueTruck9} style={{ maxWidth: "250px" }}/>
                            </Link>
                            <Link to={blueTruck10} className="gallery-item" hidden={true}>
                                <img alt="gallery5" src={blueTruck10} style={{ maxWidth: "250px" }}/>
                            </Link>
                            <Link to={blueTruck11} className="gallery-item" hidden={true}>
                                <img alt="gallery5" src={blueTruck11} style={{ maxWidth: "250px" }}/>
                            </Link>
                            <Link to={blueTruck12} className="gallery-item" hidden={true}>
                                <img alt="gallery5" src={blueTruck12} style={{ maxWidth: "250px" }}/>
                            </Link>
                            <Link to={blueTruck13} className="gallery-item" hidden={true}>
                                <img alt="gallery5" src={blueTruck13} style={{ maxWidth: "250px" }}/>
                            </Link>
                            <Link to={blueTruck14} className="gallery-item" hidden={true}>
                                <img alt="gallery5" src={blueTruck14} style={{ maxWidth: "250px" }}/>
                            </Link>
                        </LightGallery>

                        <LightGallery
                            speed={500}
                            plugins={[lgThumbnail, lgZoom]}
                            hideControlOnEnd={true}
                            loop={false}

                        >
                            <Link to={blackMclaren1} className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                                <img alt="gallery6" src={blackMclaren1} style={{ width: "100%", height: "220px" }}/>
                            </Link>
                            <Link to={blackMclaren2} className="gallery-item" hidden={true}>
                                <img alt="gallery6" src={blackMclaren2} style={{ maxWidth: "250px" }}/>
                            </Link>
                            <Link to={blackMclaren3} className="gallery-item" hidden={true}>
                                <img alt="gallery6" src={blackMclaren3} style={{ maxWidth: "250px" }}/>
                            </Link>
                            <Link to={blackMclaren4} className="gallery-item" hidden={true}>
                                <img alt="gallery6" src={blackMclaren4} style={{ maxWidth: "250px" }}/>
                            </Link>
                        </LightGallery>

                        <LightGallery
                            speed={500}
                            plugins={[lgThumbnail, lgZoom]}
                            hideControlOnEnd={true}
                            loop={false}
                        >
                            <Link to={brownCar1} className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                                <img alt="gallery7" src={brownCar1} style={{ width: "100%", height: "220px" }}/>
                            </Link>
                            <Link to={brownCar2} className="gallery-item" hidden={true}>
                                <img alt="gallery7" src={brownCar2} style={{ maxWidth: "250px" }}/>
                            </Link>
                            <Link to={brownCar3} className="gallery-item" hidden={true}>
                                <img alt="gallery7" src={brownCar3} style={{ maxWidth: "250px" }}/>
                            </Link>
                            <Link to={brownCar4} className="gallery-item" hidden={true}>
                                <img alt="gallery7" src={brownCar4} style={{ maxWidth: "250px" }}/>
                            </Link>
                            <Link to={brownCar5} className="gallery-item" hidden={true}>
                                <img alt="gallery7" src={brownCar5} style={{ maxWidth: "250px" }}/>
                            </Link>
                            <Link to={brownCar6} className="gallery-item" hidden={true}>
                                <img alt="gallery7" src={brownCar6} style={{ maxWidth: "250px" }}/>
                            </Link>
                            <Link to={brownCar7} className="gallery-item" hidden={true}>
                                <img alt="gallery7" src={brownCar7} style={{ maxWidth: "250px" }}/>
                            </Link>
                        </LightGallery>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Gallery;

import React, { useEffect, Component, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import AppRouter from './AppRouter';
import Footer from './Footer';
import TempForm from './screens/RequestAppointment';
import axios from 'axios';
import { apiRoot } from './shared/helpers';
import { blankContactInfo } from './shared/models';
import { galleries } from './shared/models';

function AppMain() {
    axios.defaults.headers.common['Authorization'] = 'Bearer FSrGCrNzpkeAKbVDzlJg1w==xD8HNQx8qUaykqnGQtEhKw==';

    const [contactInfo, setContactInfo] = useState( blankContactInfo );

    useEffect(() => {
        if (!contactInfo.Email) {
            (async () => {
                const res = await axios.get( apiRoot()+'/v2/objects/data/ContactInfo/68d97524-e399-4355-b2ff-2f28ca9d4ab6' );
                setContactInfo( res.data );
            })();
        }
    }, [contactInfo]);

    //console.log('contactInfo', contactInfo);
    //console.log('galleries', galleries);
    
  return (
    <div className='wrapper'>
      <BrowserRouter>
        <AppRouter /> 
        <TempForm />
        <Footer contactInfo={contactInfo} />
      </BrowserRouter>
    </div>
  );
}

export default AppMain;

import React, { useState, Component } from 'react';
import { Link } from 'react-router-dom';
import { Form } from "react-bootstrap";
import { useForm } from 'react-hook-form';
import axios from 'axios';

function RequestAppointment() {

    const [emailState, setEmailState] = useState('composing');
    const [departmentState, setDepartmentState] = useState('Auto Body');

    const { register, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            FirstName: '',
            LastName: '',
            Email: '',
            Department: '',
            Phone: '',
            Comments: '',
        }
    });

    const submitForm = async (values: any) => {
        setEmailState('sending');

        const requestDate = new Date();
        const data = {
            api_key: 'api-CB20EB80BCA411EAB672F23C91C88F4E',
            to: ['Caryn McKenna <cmckenna@buildinmotion.com>'],
            sender: values.FirstName + ' ' + values.LastName + ' <' + values.Email + '>',
            subject: 'Innovative Auto Works: Appointment Request',
            text_body: 'A new Appointment has been requested:\n\n' +
                'Requestor Information: \n' +
                'Name....: ' + values.FirstName + ' ' + values.LastName + "\n" +
                'Email...: ' + values.Email + "\n" +
                'Phone...: ' + values.Phone + '\n\n' +
                'Department...: ' + departmentState + '\n\n' +
                'Comments...: ' + values.Comments + '\n\n',

            html_body: '<h2>A new Appointment has been requested:</h2>' +
                '<h4 style="color:#3E787D">Requestor Information:</h4>' +
                '<table>' +
                '<tr><td width="135"><b>Name</b></td><td>' + values.FirstName + ' ' + values.LastName + '</td></tr>' +
                '<tr><td width="135"><b>Email</b></td><td>' + values.Email + '</td></tr>' +
                '<tr><td width="135"><b>Phone</b></td><td>' + values.Phone + '</td></tr>' +
                '<tr><td width="135"><b>Department</b></td><td>' + departmentState + '</td></tr>' +
                '<tr><td width="135"><b>Comments</b></td><td>' + values.Comments + '</td></tr>' +
                '</table>',

            custom_headers: [
                {
                    header: 'Reply-To',
                    value: values.FirstName + ' ' + values.LastName + ' <' + values.Email + '>',
                }
            ]
        };

        try {
            const sendEmail = await axios.post('https://api.smtp2go.com/v3/email/send', data);
            if (sendEmail && sendEmail.status && sendEmail.status === 200) {
                setEmailState('sent');
                console.log('emailSend:', sendEmail);
            }
        } catch (err) {
            setEmailState('error');
            console.log('emailError:');
        }

    };

    return (
        <div className="AppRequestAppointmentForm">
            <div className="container-dkgray padded">
                <div className="container contact-form">
                    <div className="row">
                        <div className="col-sm-12">
                            <h1>Request an Appointment</h1>
                            <h3>Send us some info about your project. Our automotive experts are waiting to assist you.</h3>
                        </div>
                    </div>

                    <form className="contactForm" onSubmit={handleSubmit(submitForm)}>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label className={'req'}>{errors.FirstName && <span>Required</span>}First Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="First Name"
                                        {...register("FirstName", { required: true, minLength: 1, maxLength: 64 })}
                                    />
                                </div>
                                <div className="form-group">
                                    <label className={'req'}>{errors.LastName && <span>Required</span>}Last Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Last Name"
                                        {...register("LastName", { required: true, minLength: 1, maxLength: 64 })}
                                    />
                                </div>
                                <div className="form-group">
                                    <label className={'req'}>{errors.Email && <span>Required</span>}Email Address</label>
                                    <input
                                        type="text"
                                        placeholder="Email"
                                        className={`form-control`}
                                        {...register("Email", {
                                            required: 'Required',
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                message: "Invalid email address"
                                            }
                                        })}
                                    />
                                </div>                                
                                <div className="form-group">
                                <label>Select Department</label>    
                                <div>                          
                                    <Form.Select value={departmentState} style={{width:"100%", height: "35px"}} onChange={event => setDepartmentState(event.target.value)}>
                                        <option>-- Select --</option>
                                        <option value="Auto Body">Auto Body</option>
                                        <option value="Parts">Parts</option>
                                        <option value="Vehicle Restyling">Vehicle Restyling</option>
                                        <option value="Electronics">Electronics</option>
                                        <option value="Powder Coating">Powder Coating</option>
                                    </Form.Select>           
                                    </div>                        
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label className={'req'}>{errors.Phone && <span>Required</span>}Phone Number</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Phone"
                                        {...register("Phone", { required: true, minLength: 1, maxLength: 64 })}
                                    />
                                </div>
                                <div className="form-group">
                                    <label className={'req'}>{errors.Comments && <span>Required</span>}Comments</label>
                                    <input
                                        type="textArea"
                                        className="form-control"
                                        {...register("Comments", { required: true, minLength: 1, maxLength: 250 })}
                                    />
                                </div>
                                {/*  <label>Attach a Photo</label>
                                    <div className="select-mimic">
                                        <div className="form-group">
                                            <input type="file" ></input>
                                        </div>
                                    </div> */}
                            </div>
                        </div>

                        <div className="row">
                            <div className="row">
                                <div className="col-sm-12 text-center">
                                    <br /><br />
                                    <div className={(emailState === "sending" ? "disabled" : emailState === "sent" ? "hidden" : "")}>
                                        <button type={"submit"} className={"btn btn-primary"} disabled={(emailState === "sending")} >Submit Message</button>
                                    </div>
                                    <div className={"messageSent " + (emailState === "sent" ? "sent" : "hidden")}>
                                        <div className="alert alert-success">
                                            <b>Appointment Request Sent!</b>
                                            <br />Your request for an appointment has been sent. A representative will be in contact with you soon.
                                        </div>
                                    </div>
                                    <div className={"estimateSendError " + (emailState === "error" ? "showError'" : "hidden")}>
                                        <div className="alert alert-danger">
                                            <b>Error!</b>
                                            <br />There was a problem sending your appointment request.<br />Please try again or call 724-933-5656 for assistance.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div >
        </div >
    );
}

export default RequestAppointment;

import React from 'react';
import './App.css';
import './iaw.scss';
import AppMain from './AppMain';

function App() {
  return (
      <AppMain />
  );
}

export default App;

import React, { } from 'react';
import { Link } from 'react-router-dom';

import Header from '../Header';
import suspension from '../assets/img/suspension-videocover.jpg';


function Suspension() {
  return (
    <div className="Suspension">
      <div className="angle-feature subpage">
        <Header title="Suspension" navOn="services" />
      </div>
      <div className="container padded">
        <div className="row">
          <div className="col-sm-4">
            <p>
              <img src={suspension} className="section-photo" />
            </p>
          </div>
          <div className="col-sm-8">
            <h3>Suspension Lifts</h3>
            <p>If you're looking to get your truck sitting with a better, more aggressive stance, we can take care of that for you. From leveling kits to lift kits and full blown custom fabricated lifts we can help you out with whatever you’re looking for. With product lines from most major manufacturers we can get you what you’re looking for. If not, we can build it. Contact our sales team today to see what we can do for you.</p>

            <h3>Suspension Lowering</h3>
            <p>Stance on a vehicle can make an immense impact on how it looks. It can also play a large roll on how your car handles. With access to lowering springs and fully adjustable Coil-Overs, we can find something to get your car or truck sitting where you want it. Contact our sales team today to see what we can do for you.</p>

            <h3>Air Suspension</h3>
            <p>If you're looking for the best in driveablitly, performance, and adjustability then look no further. If you want to lift or lower your vehicle with an air suspension we can do that for you.</p>
            <p>Looking to have your car or truck ride as low as possible while cruising but need to get up your steep driveway at home? Air can help. Thinking of Coil-Overs so your car can handle better but don’t want to deal with the constant hassle of avoiding steep transitions in parking garages? Air can help. Give us a call to see how riding on air can work for you.</p>
            <p>Air suspension isn't just for lowering! If you are looking for a better ride and increased load carrying in your 3/4 or 1 ton truck we carry a full line of products from Kelderman Suspension that can help you. With a wide variety of heights ranging from stock to 10” of lift there is something for all needs. Contact our sales team today to see what we can do for you.</p>

            <h3>Wheels</h3>
            <p>Wheels can make or break a vehicle, luckily they are one of the easiest things to change on your vehicle. We access to the leading wheel brands in the industry. Match that with our years of experience and you have everything you need to have your vehicle looking right. Doesn’t matter if you're looking for a custom machined 3 piece wheel for your track car or a forged off road wheel for your truck we can handle that and everything in between. Contact our sales team today to see what we can do for you.</p>

            <h3>Tires</h3>
            <p>With access to all major brands we can get what you're looking for. With state-of-the-art touchless mounting and balancing equipment your wheels are in good hands. Contact our sales team today to see what tires are right for you.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Suspension;
